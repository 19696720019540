import styled from 'styled-components'
import { Button, Heading, Text, LogoIcon } from '@pancakeswap/uikit'
import Page from 'components/Layout/Page'
import { useTranslation } from '@pancakeswap/localization'
import Link from 'next/link'

const StyledNotFound = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  height: calc(100vh - 64px);
  justify-content: center;
  .go-back {
    color: #F58227;
    border-radius: 8px;
    border: 1px solid;
    width: 180px;
    height: 40px;
  }
  .img-inner {
    position: relative;
    @media (max-width: 767.98px) {
       width:80% ;
    }
    .img-icon {
      position: absolute;
      animation-iteration-count: infinite;     
    }
    .img-icon-1 {
      top: -50px;
      left: -25px;
      animation-name: scale;
      animation-timing-function: ease-out;
      animation-duration: 4s;
      @media (max-width: 767.98px) {
        top: -30px;
        left: -15px;
        width:50px ;
      }
    }
    .img-icon-2 {
      left: -45px;
      bottom: 80px;
      animation-name: scale;
      animation-timing-function: ease-out;
      animation-duration: 4s;
      @media (max-width: 767.98px) {
        left: -25px;
        bottom: 60px;
        width:40px ;
      }
    }
    .img-icon-3 {
      right: -30px;
      animation-name: scale;
      animation-timing-function: ease-out;
      animation-duration: 4s;
      @media (max-width: 767.98px) {
        right: -25px;
        width:50px ;
      }
    }
    .img-icon-4 {
      right: -36px;
      bottom: 40px;
      animation-name: scale;
      animation-timing-function: ease-out;
      animation-duration: 4s;
      @media (max-width: 767.98px) {
        right: -25px;
        bottom: 10px;
        width:45px ;
      }
    }
  }
  @keyframes upDown {
    0% {
      transform: translate(0, 0);
    }
    50% {
      transform: translate(0, -40px);
    }
    100% {
      transform: translate(0, 0);
    }
  }

  @keyframes scale {
    0% {
      transform: scale(1);
    }
    50% {
      transform: scale(1.2);
    }
    100% {
      transform: scale(1);
    }
  }


`

const NotFound = () => {
  const { t } = useTranslation()

  return (
    <Page>
      <StyledNotFound>
        <div className="img-inner">
          <img src="/images/404/404.png" alt="404" />
          <img className="img-icon img-icon-1" src="/images/404/red-mark.png" alt="img-1" />
          <img className="img-icon img-icon-2" src="/images/404/magent-heart.png" alt="img-1" />
          <img className="img-icon img-icon-3" src="/images/404/pink-heart.png" alt="img-1" />
          <img className="img-icon img-icon-4" src="/images/404/mess.png" alt="img-1" />
        </div>
        <Heading scale="xl" color="#101828" mb="10px">
          {t('Page Not Found')}
        </Heading>
        <Text mb="40px" color="#101828">
          {t('Hmm. We’re having trouble finding that page. Please Back to Home')}
        </Text>
        <Link href="/" passHref>
          <Button as="a" scale="sm" variant="secondary" color="#D4156D" width="160px" height="40px" className="go-back">
            {t('Go Back Home')}
          </Button>
        </Link>
      </StyledNotFound>
    </Page>
  )
}

export default NotFound
